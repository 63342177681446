import React, { Component } from 'react'
import { updateObject, checkValidity } from '../../../shared/utility'
import Form from '../../UI/Form/Form'
import axios from 'axios'

class PasswordReset extends Component {
  state = {
    form: {
      email: {
        elementType: 'input',
        elementConfig: {
          type: 'email',
          placeholder: 'example@gmail.com'
        },
        value: '',
        label: 'Email',
        validation: {
          required: true,
          isEmail: true
        },
        helperText: 'Not a valid email',
        valid: false,
        touched: false
      }
    },
    formIsValid: false,
    loading: false,
    error: false,
    errorMessage: null,
    successMessage: null
  }

  inputChangedHandler = (event, inputIdentifier) => {
    const updateFormElement = updateObject(this.state.form[inputIdentifier], {
      value: event.target.value,
      valid: checkValidity(
        event.target.value,
        this.state.form[inputIdentifier].validation
      ),
      touched: true
    })

    const updatedForm = updateObject(this.state.form, {
      [inputIdentifier]: updateFormElement
    })

    let formIsValid = true

    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid
    }

    this.setState({
      form: updatedForm,
      formIsValid: formIsValid
    })
  }

  handleSubmit = () => {
    this.setState({
      loading: true,
      error: null,
      errorMessage: null,
      successMessage: null
    })

    const data = {
      requestType: 'PASSWORD_RESET',
      email: this.state.form.email.value
    }

    const url =
      'https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=AIzaSyBHfcEH7VA616uzPkDIi8pm6hZvwxOvFzk'

    axios
      .post(url, data)
      .then(res => {
        this.setState({
          loading: false,
          successMessage: `Reset password email has been sent to ${res.data.email}`
        })
      })
      .catch(err => {
        this.setState({ error: true, loading: false })

        if (err.response.data.error.code === 400) {
          this.setState({
            errorMessage: 'Unfortunately email was not found.'
          })
        }
      })
  }

  closeAlertHandler = () => {
    this.setState({
      successMessage: null
    })
  }

  closeErrorHandler = () => {
    this.setState({
      error: false
    })
  }

  render() {
    const formElements = []

    for (let key in this.state.form) {
      formElements.push({
        id: key,
        config: this.state.form[key]
      })
    }

    return (
      <Form
        error={this.state.error}
        closeError={this.closeErrorHandler}
        errorMessage={this.state.errorMessage}
        successMessage={this.state.successMessage}
        closeAlert={this.closeAlertHandler}
        title="Reset Password"
        inputs={formElements}
        onSubmit={this.handleSubmit}
        chageHandler={this.inputChangedHandler}
        spinner={this.state.loading}
        disabled={!this.state.formIsValid}
      />
    )
  }
}

export default PasswordReset
