import React from 'react'
import Layout from '../hoc/Layout/Layout'
import PasswordReset from '../components/Pages/PasswordReset/PasswordReset'
import SEO from '../components/seo'

export default () => {
  return (
    <Layout>
      <SEO
        title="Reset Password | PMP Certification"
        keywords={[`PMP`, `PMP Exam`, `Project Management`]}
        description={`Exam Questions to get PMP Certification. Unlimited Questions to Sharpen Your Skills with In-depth Explanations.`}
      ></SEO>
      <PasswordReset />
    </Layout>
  )
}
